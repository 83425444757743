var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-data-table', {
    staticClass: "table-section text-no-wrap",
    attrs: {
      "headers": _vm.columns,
      "items": _vm.items,
      "options": _vm.options,
      "server-items-length": _vm.itemsTotal,
      "loading": _vm.loading,
      "header-props": _vm.headerprops
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.id) + " ")];
      }
    }, {
      key: "item.distributor_id",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('router-link', {
          staticClass: "font-weight-medium text-decoration-none",
          attrs: {
            "to": {
              name: 'distributor-view',
              params: {
                id: item.distributor_id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.distributor_id) + " ")])];
      }
    }, {
      key: "item.period_start_at",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(_vm.dayjs(item.period_start_at).utc().local().format("YYYY-MM-DD")))])];
      }
    }, {
      key: "item.period_end_at",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(_vm.dayjs(item.period_end_at).utc().local().format("YYYY-MM-DD")))])];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "color": item.status === 'completed' ? 'success' : 'warning'
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(item.status)) + " ")])];
      }
    }, {
      key: "item.created_at",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(_vm.formatDate(item.created_at)))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "d-flex justify-center"
        }, [_vm.$can('medical_card_report:download') ? _c('v-icon', {
          on: {
            "click": function click($event) {
              return _vm.download(item.id);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiDownload) + " ")]) : _vm._e()], 1)];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }