var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-select', {
    attrs: {
      "placeholder": _vm.$t('common.list.selectStatus'),
      "items": _vm.options,
      "item-value": "value",
      "item-text": "title",
      "single-line": "",
      "outlined": "",
      "clearable": "",
      "hide-details": ""
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }