<template>
  <v-form
    ref="formRef"
    @submit.prevent="onSubmit"
  >
    <v-card>
      <v-card-title class="text-sm-h5">
        <slot name="title">
          Create Medical Card Report
        </slot>
      </v-card-title>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
          >
            <v-autocomplete
                v-model="form.distributor_id"
                :placeholder="$t('common.list.selectDistributor')"
                :items="distributorOptions"
                item-text="title"
                item-value="value"
                single-line
                outlined
                clearable
                hide-details
                :rules="[validators.required]"
              >
            </v-autocomplete>

          </v-col>

          <v-col cols="6">
            <v-menu
              v-model="isPeridStartAtDatePickerVisible"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.period_start_at"
                  readonly
                  v-bind="attrs"
                  label="Period Start At"
                  persistent-hint
                  outlined
                  v-on="on"
                  :rules="[validators.required]"
                />
              </template>

              <v-date-picker
                v-model="form.period_start_at"
                color="primary"
                @input="isPeridStartAtDatePickerVisible = false"
                :max="maxDate"
              />
            </v-menu>
          </v-col>

          <v-col cols="6">
            <v-menu
              v-model="isPeridEndAtDatePickerVisible"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.period_end_at"
                  readonly
                  v-bind="attrs"
                  label="Period End At"
                  persistent-hint
                  outlined
                  v-on="on"
                  :rules="[validators.required]"
                />
              </template>

              <v-date-picker
                v-model="form.period_end_at"
                color="primary"
                @input="isPeridEndAtDatePickerVisible = false"
                :max="maxDate"
              />
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="error"
          :disabled="loading"
          @click="onClose"
        >
          Close
        </v-btn>
        <v-btn
          type="submit"
          color="primary"
          :loading="loading"
          :disabled="loading"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { t } from '@/plugins/i18n'
import { required } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline, mdiPlus, mdiRefresh } from '@mdi/js'
import { ref } from '@vue/composition-api'

// Components
import { useDistributor } from '@/composables'
import { useIgnoreNullValue } from '@/composables'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export default {
  components: {},

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
  },

  setup(props, { emit }) {
    const isPeridStartAtDatePickerVisible = ref(false)
    const isPeridEndAtDatePickerVisible = ref(false)

    const defaultFormValue = {
      distributor_id: null,
      period_start_at: dayjs().startOf('month').format('YYYY-MM-DD'),
      period_end_at: dayjs().format('YYYY-MM-DD'),
    }

    const maxDate = dayjs().format('YYYY-MM-DD')

    const form = ref({
      ...defaultFormValue,
    })
    const formRef = ref(null)

    const { distributorOptions } = useDistributor()

    const onClose = () => {
      resetForm()

      emit('close')
    }

    const onSubmit = () => {
      if (formRef.value.validate()) {
        const data = useIgnoreNullValue(form.value)

        data.period_start_at = dayjs(data.period_start_at).utc().local().format()
        data.period_end_at = dayjs(data.period_end_at).endOf('day').utc().local().format()

        emit('submit', data)
      }
    }

    const resetForm = () => {
      formRef.value.reset()
      Object.assign(form.value, defaultFormValue)
    }

    return {
      t,

      isPeridStartAtDatePickerVisible,
      isPeridEndAtDatePickerVisible,

      form,
      formRef,
      distributorOptions,

      maxDate,

      onClose,
      onSubmit,
      resetForm,

      // Rules
      validators: {
        required,
      },

      icons: {
        mdiPlus,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiRefresh,
      },
    }
  },
}
</script>
