import axios from '@axiosV2'

const index = params =>
  axios.get('/admin/medical/card-reports', {
    params,
  })
const store = data => axios.post('/admin/medical/card-reports', data)
const download = async id => {
  const data = await axios.post(
    `/admin/medical/card-reports/${id}/download`,
    {},
    {
      responseType: 'arraybuffer',
    },
  )

  const date = new Date().toISOString().split('T')[0]
  let blob = new Blob([data], { type: 'text/xlsx' })

  let link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = `medical_card_reports-${date}.xlsx`
  link.click()
}

export default {
  index,
  store,
  download,
}
