<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="800"
  >
    <!-- Dialog Activator -->
    <template #activator="{ on, attrs }">
      <v-btn
        color="success"
        outlined
        class="mb-1 me-3"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          size="17"
          class="me-1"
        >
          {{ icons.mdiPlus }}
        </v-icon>
        <span>Create</span>
      </v-btn>
    </template>

    <!-- Dialog Content -->
    <report-form
      ref="formRef"
      @close="onClose"
      @submit="createReport"
      :loading="loading"
    />
  </v-dialog>
</template>

<script>
import { useNotifySuccess } from '@/composables'
import { mdiPlus, mdiRefresh } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import { reportService } from '../../services'
import ReportForm from './Form.vue'

export default {
  components: {
    ReportForm,
  },
  setup(_, { emit }) {
    const isDialogVisible = ref(false)

    const loading = ref(false)
    const formRef = ref(null)

    const onClose = () => {
      isDialogVisible.value = false
    }

    const createReport = async data => {
      try {
        loading.value = true

        await reportService.store(data)

        isDialogVisible.value = false

        formRef.value.resetForm()

        emit('created')

        useNotifySuccess({
          content: 'Medical card report initiated successfully.',
        })
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    return {
      isDialogVisible,

      loading,
      formRef,

      onClose,
      createReport,

      icons: {
        mdiPlus,
        mdiRefresh,
      },
    }
  },
}
</script>
