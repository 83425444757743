<template>
  <v-card>
    <v-data-table
      :headers="columns"
      :items="items"
      :options.sync="options"
      :server-items-length="itemsTotal"
      :loading="loading"
      :header-props="headerprops"
      class="table-section text-no-wrap"
    >
      <!-- ID -->
      <template #[`item.id`]="{ item }">
        {{ item.id }}
      </template>

      <!-- Distributor -->
      <template #[`item.distributor_id`]="{ item }">
        <router-link
          class="font-weight-medium text-decoration-none"
          :to="{ name: 'distributor-view', params: { id: item.distributor_id } }"
        >
          {{ item.distributor_id }}
        </router-link>
      </template>

      <!-- Period Start Date -->
      <template #[`item.period_start_at`]="{ item }">
        <span class="text-no-wrap">{{ dayjs(item.period_start_at).utc().local().format("YYYY-MM-DD") }}</span>
      </template>

      <!-- Period End Date -->
      <template #[`item.period_end_at`]="{ item }">
        <span class="text-no-wrap">{{ dayjs(item.period_end_at).utc().local().format("YYYY-MM-DD") }}</span>
      </template>

      <!-- Status -->
      <template #[`item.status`]="{ item }">
        <v-chip
          small
          :color="item.status === 'completed' ? 'success' : 'warning'"
        >
          {{ $t(item.status) }}
        </v-chip>
      </template>

      <!-- date -->
      <template #[`item.created_at`]="{item}">
        <span class="text-no-wrap">{{ formatDate(item.created_at) }}</span>
      </template>

      <!-- Actions -->
      <template #[`item.actions`]="{ item }">
        <div class="d-flex justify-center">
            <v-icon v-if="$can('medical_card_report:download')" @click="download(item.id)">
              {{ icons.mdiDownload }}
            </v-icon>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { mdiDelete, mdiDotsVertical, mdiDownload } from '@mdi/js'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { reportService } from '../../services'

dayjs.extend(utc)

export default {
  components: {},
  props: {
    tableList: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { loading, options, paginate, items, itemsTotal, headerprops, loadResource, resource } = props.tableList

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'Distributor', value: 'distributor_id' },
      { text: 'Period Type', value: 'period_type' },
      { text: 'Period Start', value: 'period_start_at' },
      { text: 'Period End', value: 'period_end_at' },
      { text: 'Status', value: 'status' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Actions', value: 'actions', sortable: false },
    ]

    const download = async id => {
      await reportService.download(id)
    }

    return {
      t,
      dayjs,
      formatDate,

      resource,
      loadResource,

      // Table config
      loading,
      paginate,
      options,
      items,
      itemsTotal,
      headerprops,

      columns,

      download,

      icons: {
        mdiDotsVertical,
        mdiDelete,
        mdiDownload,
      },
    }
  },
}
</script>
